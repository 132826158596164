<template>

  <div class="special">
    <h1>This is an special page</h1>
    <div>
        
    </div>
  </div>
 
</template>

<script>
export default {

}
</script>

<style>

</style>